import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SearchBar from "../../components/search/SearchBar"

const BlogSingle = ({ data }) => {
  const { date, title, image, body } = data.contentfulBlog
  const pathToImage = getImage(image)

  return (
    <Layout>
      <Seo title={title} />
      <div className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="">
          <nav
            aria-label="Breadcrumb"
            className="max-w-2xl mx-auto py-6 px-4 flex justify-between items-center flex-col md:flex-row space-x-2 sm:px-6 lg:max-w-7xl lg:px-8"
          >
            <ul className="max-w-2xl px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">
              <Link
                to="/"
                className=" text-sm font-medium text-gray-900 hover:text-gray-600"
              >
                Home
              </Link>
              <svg
                width={16}
                height={20}
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-5 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
              <Link
                to="/news"
                className=" text-sm font-medium text-gray-900 hover:text-gray-600"
              >
                News
              </Link>
              <svg
                width={16}
                height={20}
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-5 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
              <p className="text-xs font-medium text-sky-600">{title}</p>
            </ul>
            <SearchBar />
          </nav>
          <div className="relative w-full px-6 py-12 bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28">
            <GatsbyImage
              image={pathToImage}
              alt={title}
              className="w-full h-full object-center object-contain"
            />
            <h2 className="text-xl my-7 md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
              {title}
            </h2>
            <p className="text-sm font-normal mb-3">{date}</p>
            <div className="mt-8 prose prose-slate mx-auto lg:prose-lg prose-sky">
              <div>{body && renderRichText(body, {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
    }
  })
}
</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query getBost($slug: String) {
    contentfulBlog(slug: { eq: $slug }, features: { eq: true }) {
      id
      date(formatString: "DD-MM-YYYY")
      slug
      tags
      title
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      body {
        raw
      }
      description
    }
  }
`
export default BlogSingle
